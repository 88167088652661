:root {
  --Primary-color: #0f2d6a;
  --Secondary-color: #00aeef;
  --White: #ffffff;
  --Grey-1: #f2f2f2;
  --Grey-2: #f2f5f8;
  --Grey-3: #edf0f5;
  --Grey-4: #e4e5e7;
  --Grey-5: #6a6c6f;
}

.MuiLinearProgress-root {
  background-color: var(--White) !important;
}

.MuiLinearProgress-bar {
  background-color: var(--Grey-4) !important;
}
