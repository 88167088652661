/* Font Family Definition */
:root {
  --system-font: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

/* Global Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  font-family: var(--system-font);
}

/* Base Styles */
body {
  background-color: var(--Grey-3);
  font-family: var(--system-font) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Material-UI Component Overrides */
.MuiTypography-root,
.MuiButtonBase-root,
.MuiInputBase-root,
.MuiFormHelperText-root,
.MuiTableCell-root,
.MuiTablePagination-caption,
.MuiTypography-body2,
.MuiAccordionSummary-root {
  font-family: var(--system-font) !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  border-radius: 50% !important;
  margin: 0.2rem !important;
  width: 3em !important;
  line-height: 3em !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #00aeef !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.5rem !important;
  margin: 0.5rem !important;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
  font-size: 1.2rem !important;
}

.react-datepicker__year-option,
.react-datepicker__month-option {
  font-size: 1.2rem !important;
  padding: 0.4rem 0rem;
}

.react-datepicker__navigation-icon--next,
.react-datepicker__navigation-icon--previous,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 30% !important;
}

.react-datepicker__month-dropdown-container {
  margin-right: 2.5rem !important;
}

.react-datepicker-popper {
  z-index: 100 !important;
}


/* Other UI Component Styles */
.remove_spin input[type="number"]::-webkit-inner-spin-button,
.remove_spin input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  color: var(--Primary-color) !important;
}

/* Material-UI Specific Overrides */
.MuiAccordionSummary-root {
  background-color: var(--Primary-color) !important;
  border-radius: 4px !important;
  min-height: 64px !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiAccordionSummary-expandIcon,
.MuiAccordionSummary-expandIcon.Mui-expanded {
  color: var(--White) !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-root {
  padding: 0 0.5em !important;
}

.MuiCheckbox-colorSecondary.Mui-checked,
.MuiRadio-colorSecondary.Mui-checked {
  color: var(--Primary-color) !important;
}

.MuiOutlinedInput-root {
  border-radius: 0 !important;
}

.MuiLinearProgress-root {
  margin-top: 0.2rem !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: var(--Secondary-color) !important;
}

.LoaderDeterminate .MuiLinearProgress-colorPrimary {
  background-color: var(--Grey-4) !important;
}

.LoaderDeterminate .MuiLinearProgress-barColorPrimary {
  background-color: var(--Secondary-color) !important;
}

.MuiTableCell-root {
  border-color: var(--Grey-4) !important;
}

.makeStyles-root-71,
.makeStyles-root-1049 {
  background-color: var(--Grey-1) !important;
}

.customTooltip .MuiTooltip-popper {
  z-index: 1 !important;
}
